import Cookies from "js-cookie";

export const BASE_URL =
  process.env.REACT_APP_SERVER_URL ||
  //"http://localhost:8068"
  "https://api.hyper.itmsgh.com";

export const BASE_URL2 =
  process.env.REACT_APP_SERVER_URL ||
  // "http://localhost:3030"
  "https://api.mmt.itmsgh.com";

class Auth {
  getCipher() {
    return Cookies.get("/t") || null;
  }

  setCipher(token, data = {}) {
    Cookies.set("/t", token);
    Cookies.set("/r", data?.role);
    sessionStorage.setItem("data", JSON.stringify(data));
  }

  clearCipher() {
    Cookies.remove("/t");
    sessionStorage.clear();
  }
}

export default new Auth();
